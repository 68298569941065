import React, { useEffect } from 'react';

const UnderdogMediaAd = () => {
    const hostname = window.location.hostname;

    useEffect(() => {
        if (hostname !== 'culinarycreationscorner.com') {
            return;
        }

        setTimeout(() => {
            const adContainer = document.getElementById('udm-ad-slot');
            if (!adContainer) return;

            try {
                window.top.udm_inpage_sid = 20779;
            } catch (e) {
                console.warn("Error initializing UDM inpage. Please verify you are not using an unfriendly iframe.");
            }

            const loaderScript = document.createElement('script');
            loaderScript.src = 'https://bid.underdog.media/inpageLoader.js';
            loaderScript.async = true;
            adContainer.appendChild(loaderScript);
        }, 100); // Delay of 100ms

        return () => {
            const adSlot = document.getElementById('udm-ad-slot');
            if (adSlot) {
                adSlot.innerHTML = '';
            }
        };
    }, [hostname]);

    if (hostname !== 'culinarycreationscorner.com') {
        return null; // Render nothing for other domains
    }

    return <div id="udm-ad-slot" className="udm-inpage" style={{ width: '100%', height: 'auto' }} />;
};

export default UnderdogMediaAd;