import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

const TaboolaAd = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const hostname = window.location.hostname;

    // Explicit configuration for each domain
    const taboolaTypConfig = {
        'ainewspaper.net': {
            mode: 'thumbnails-a-3x2',
            placement: 'Below Article Thumbnails',
            targetId: 'taboola-below-article-thumbnails',
            scriptSrc: '//cdn.taboola.com/libtrc/typ-network/loader.js',
        },
        'activewellnessways.com': {
            mode: 'alternating-thumbnails-a',
            placement: 'Below Article Thumbnails',
            targetId: 'taboola-below-article-thumbnails',
            scriptSrc: '//cdn.taboola.com/libtrc/cloundadsdesktop-network/loader.js',
        },
        'procasacraft.com': {
            mode: 'alternating-thumbnails-a',
            placement: 'Below Article Thumbnails',
            targetId: 'taboola-below-article-thumbnails',
            scriptSrc: '//cdn.taboola.com/libtrc/cloundadsdesktop-network/loader.js',
        },
        'finanzyhub.com': {
            mode: isMobile ? 'thumbnails-a' : 'thumbs-feed-01',
            placement: isMobile ? 'taboola-mobile-below-article-thumbnails' : 'Desktop Below Article Feed',
            targetId: isMobile ? 'Mobile below article thumbnails' : 'taboola-desktop-below-article-feed',
            scriptSrc: '//cdn.taboola.com/libtrc/typ-network/loader.js',
        },
        'findallhealth.com': {
            mode: isMobile ? 'thumbnails-a' : 'thumbs-feed-01',
            placement: isMobile ? 'taboola-mobile-below-article-thumbnails' : 'Desktop Below Article Feed',
            targetId: isMobile ? 'Mobile below article thumbnails' : 'taboola-desktop-below-article-feed',
            scriptSrc: '//cdn.taboola.com/libtrc/typ-network/loader.js',
        },
        'homeboz.com': {
            mode: isMobile ? 'thumbnails-a' : 'thumbs-feed-01',
            placement: isMobile ? 'taboola-mobile-below-article-thumbnails' : 'Desktop Below Article Feed',
            targetId: isMobile ? 'Mobile below article thumbnails' : 'taboola-desktop-below-article-feed',
            scriptSrc: '//cdn.taboola.com/libtrc/typ-network/loader.js',
        },
        'moneymasterymatters.com': {
            mode: isMobile ? 'thumbnails-a' : 'thumbs-feed-01',
            placement: isMobile ? 'taboola-mobile-below-article-thumbnails' : 'Desktop Below Article Feed',
            targetId: isMobile ? 'Mobile below article thumbnails' : 'taboola-desktop-below-article-feed',
            scriptSrc: '//cdn.taboola.com/libtrc/typ-network/loader.js',
        },
        'motorenthusiastworld.com': {
            mode: isMobile ? 'thumbnails-a' : 'thumbs-feed-01',
            placement: isMobile ? 'taboola-mobile-below-article-thumbnails' : 'Desktop Below Article Feed',
            targetId: isMobile ? 'Mobile below article thumbnails' : 'taboola-desktop-below-article-feed',
            scriptSrc: '//cdn.taboola.com/libtrc/typ-network/loader.js',
        },
        'nomadicnotes.net': {
            mode: isMobile ? 'thumbnails-a' : 'thumbs-feed-01',
            placement: isMobile ? 'taboola-mobile-below-article-thumbnails' : 'Desktop Below Article Feed',
            targetId: isMobile ? 'Mobile below article thumbnails' : 'taboola-desktop-below-article-feed',
            scriptSrc: '//cdn.taboola.com/libtrc/typ-network/loader.js',
        },
        'smarttechtalks.com': {
            mode: isMobile ? 'thumbnails-a' : 'thumbs-feed-01',
            placement: isMobile ? 'taboola-mobile-below-article-thumbnails' : 'Desktop Below Article Feed',
            targetId: isMobile ? 'Mobile below article thumbnails' : 'taboola-desktop-below-article-feed',
            scriptSrc: '//cdn.taboola.com/libtrc/typ-network/loader.js',
        },
    };

    const config = taboolaTypConfig[hostname];

    useEffect(() => {
        console.log(`Loading Taboola script for: ${hostname}`);

        if (!config) {
            console.warn(`No Taboola configuration found for hostname: ${hostname}`);
            return;
        }

        const existingScript = document.getElementById('taboola-loader-script');
        if (existingScript && existingScript.src.includes(config.scriptSrc)) {
            console.log('Correct Taboola script already loaded.');
        } else {
            if (existingScript) {
                console.warn('Removing previous Taboola script...');
                existingScript.remove(); // Remove old script if different
            }

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            script.src = config.scriptSrc; // Use the scriptSrc from the configuration
            script.id = 'taboola-loader-script';
            script.onload = () => {
                console.log('Taboola script loaded successfully.');
                window._taboola = window._taboola || [];
                window._taboola.push({ article: 'auto' }); // Initial Taboola push
                window._taboola.push({
                    mode: config.mode,
                    container: config.targetId,
                    placement: config.placement,
                    target_type: 'mix',
                });
                window._taboola.push({ flush: true });
                console.log('Taboola ad configuration pushed and flushed.');
            };
            script.onerror = () => console.error('Failed to load Taboola script.');
            document.head.appendChild(script);
        }
    }, [hostname, config]);

    if (!config) {
        return null; // No ad rendered if the configuration is missing
    }

    return (
        <div id={config.targetId} >
            {/* Visual Debug for the ad container */}
            <p style={{ fontSize: '12px', textAlign: 'center', color: '#666' }}>
                Loading Taboola Ad... (Target ID: {config.targetId})
            </p>
        </div>
    );
};

export default TaboolaAd;